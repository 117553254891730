import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectFade } from "swiper/modules"

const HeroSlider = ({ hero }) => {
  const [imageOffset, setImageOffset] = React.useState(20)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setImageOffset(prevOffset => {
        if (prevOffset === -20) return 20

        return prevOffset - 0.1
      })
    }, 20)
    return () => clearInterval(interval)
  }, [])
  return (
    <Swiper
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      effect="fade"
      modules={[Autoplay, EffectFade]}
      onSlideChange={e => {
        setImageOffset(20)
      }}
      className="mySwiper"
    >
      {hero.sliderImages?.map((image, index) => {
        if (image?.image) {
          return (
            <SwiperSlide>
              <img
                key={index}
                src={image.image}
                alt={image.alttext}
                style={{
                  transform: `translateX(${imageOffset}px)`,
                }}
              />
            </SwiperSlide>
          )
        }
      })}
    </Swiper>
  )
}

export default HeroSlider
